/* eslint-disable @next/next/no-img-element */
import SearchBC from '@components2/SearchBC';
import { useMobxStores } from '@stores/index';
import { observer } from 'mobx-react-lite';
import React from 'react';
import ButtonSearchBC from './ButtonSearchBC';

function HomeSearchBC({ airports, deviceType }) {
  const { uiStore } = useMobxStores();

  return (
    <>
      <div
        className={`h-[31rem] z-10 flex flex-col justify-center items-center relative
          ${
            uiStore.searchSwitchTerm === 'airport'
              ? `${
                  deviceType === 'desktop'
                    ? 'bg-search-airportAB'
                    : 'bg-search-airportAB-mobile'
                }`
              : uiStore.searchSwitchTerm === 'cruise'
              ? `${
                  deviceType === 'desktop'
                    ? 'bg-search-cruiseAB'
                    : 'bg-search-cruiseAB-mobile'
                }`
              : uiStore.searchSwitchTerm === 'city'
              ? `${
                  deviceType === 'desktop'
                    ? 'bg-search-cityAB'
                    : 'bg-search-cityAB-mobile'
                }`
              : `${
                  deviceType === 'desktop'
                    ? 'bg-search-hotelAB'
                    : 'bg-search-hotelAB-mobile'
                }`
          }
            `}
      >
        <div className="container mx-auto px-4 md:px-16  ">
          <h2 className="table mx-auto text-2xl md:text-4xl text-center text-white font-bold px-auto px-2 rounded-2xl shadow-searchAB">
            Reserve premium parking at affordable rates.
          </h2>
          <h2 className="table mx-auto text-xl md:text-2xl text-center text-white font-bold px-auto pb-2 rounded-2xl shadow-searchAB">
            Park, Save, Relax!
          </h2>
          <div className="bg-white bg-opacity-90 md:bg-opacity-95 rounded-2xl py-6 px-8 lg:px-20 w-full md:w-3/5 mx-auto shadow-md h-[23.5rem]">
            <div className="z-20">
              <ButtonSearchBC />
              {uiStore.searchSwitchTerm === 'airport' ? (
                <SearchBC
                  key="aa"
                  searchType={'airport'}
                  airports={airports}
                  deviceType={deviceType}
                />
              ) : uiStore.searchSwitchTerm === 'cruise' ? (
                <SearchBC
                  key="ab"
                  searchType={'cruise'}
                  airports={airports}
                  deviceType={deviceType}
                />
              ) : (
                <SearchBC searchType="city" deviceType={deviceType} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(HomeSearchBC);
