import Features from '@/components2/Features';
import { useMobxStores } from '@stores/index';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useState, Suspense } from 'react';
import { airportCarousel } from '@/utils/airportCarouselContent';
import AirportCarouselHomeB from '@/components2/AirportCarouselHomeB';
import { basicPageProps, mobileProps } from '@/utils/isServer';
import ModalDownloadApp from '@/components2/ModalDownloadApp';
import HomeSearchBC from '@/components/HomeSearchBC';
import ReviewsCarouselHome from '@/components2/ReviewsCarouselHome';
import { cacheFetch } from '@/helpers/cacheFetch';

const Airports = dynamic(() => import('@/components2/Airports'), {
  ssr: true,
});

const Guides = dynamic(() => import('@/components2/Guides'), {
  ssr: true,
});

const Monthlies = dynamic(() => import('@/components2/Monthlies'), {
  ssr: true,
});

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { isMobile, isIos } = mobileProps(ctx);
  const { airports, configInfo } = await basicPageProps(ctx);

  const reviews = await cacheFetch(
    `${process.env.FORCE_BLOG_ADDRESS}/blog/review/review.json`
  );

  return {
    props: {
      airports,
      deviceType: isMobile ? 'mobile' : 'desktop',
      mobileType: isIos ? 'ios' : 'android',
      reviews,
      mobileFeeDiscount: configInfo.mobileFeeDiscount || null,
    },
  };
};

function Home({
  airports,
  deviceType,
  mobileType,
  reviews,
  mobileFeeDiscount,
}) {
  const { authStore, searchStore } = useMobxStores();
  const [loggedIn, setLoggedIn] = useState(false);
  const { uiStore } = useMobxStores();

  useEffect(() => {
    setLoggedIn(authStore.signedIn);
  }, [authStore, authStore.signedIn]);

  useEffect(() => {
    searchStore.clearAirportAndCityStorages();
  }, [searchStore]);

  return (
    <div className="bg-white">
      <Head>
        <meta
          name="description"
          content="Find your local airport and reserve the cheapest airport parking through On Air Parking. We guarantee the lowest rates with the most professional facilities. Free shuttles and cancellations are always included. Book now!"
        />
        <title>
          Find your local airport and reserve the cheapest airport parking
          through On Air Parking. We guarantee the lowest rates with the most
          professional facilities. Free shuttles and cancellations are always
          included. Book now!
        </title>
      </Head>
      <HomeSearchBC airports={airports} deviceType={deviceType} />

      <div className="mx-auto px-auto bg-white  airports-slider">
        <div className={`${'bg-whyOnAir'}`}>
          <Features />
        </div>
        <Suspense>
          <div className="px-4 md:px-0">
            <AirportCarouselHomeB
              items={airportCarousel}
              deviceType={deviceType}
            />
            <ReviewsCarouselHome deviceType={deviceType} reviews={reviews} />
          </div>
        </Suspense>
      </div>
      <Suspense>
        {mobileFeeDiscount > 0 && (
          <ModalDownloadApp mobileFeeDiscount={mobileFeeDiscount} />
        )}

        <h2 className="text-center text-2xl md:text-4xl mt-8 mb-4 font-bold">
          Discover More
        </h2>
        <p className="text-center text-xl md:text-2xl mb-6">
          Where you can park, Guides and Monthly Parking
        </p>

        <Airports airports={airports} />
        <Guides airports={airports} />
        <Monthlies airports={airports} />
      </Suspense>
    </div>
  );
}
Home.layout = 'DefaultLayout2';
export default Home;
