import React, { useState, useEffect } from 'react';
import { CDN2 } from '@/helpers/api';
import { Divider, Modal } from 'antd';
import Image from 'next/image';
import { CloseOutlined } from './Icons';
import Link from 'next/link';

export default function ModalDownloadApp({ mobileFeeDiscount }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (sessionStorage.getItem('show') == '1') {
        return;
      }
      sessionStorage.setItem('show', '1');
      setIsModalOpen(true);
    }, 500);
  }, []);

  return (
    <div className="flex flex-col">
      <Modal
        className="modal-download-app"
        bodyStyle={{ backgroundColor: '#ffffff' }}
        visible={isModalOpen}
        closable={false}
        onCancel={handleCancel}
        title={
          <div className="flex justify-end -mb-20">
            <CloseOutlined onClick={handleCancel} />
          </div>
        }
        footer={
          <p
            className="text-secondary font-bold cursor-pointer text-center"
            onClick={handleCancel}
          >
            Thanks, but I'd prefer to stay on the website.
          </p>
        }
        destroyOnClose={true}
      >
        <div className="flex flex-col items-center mx-auto">
          <h2 className="text-lg md:text-xl text-center px-4">
            <b>Exclusive in the App!</b>
          </h2>
          <p className="text-lg text-center px-4">
            Get <b>${mobileFeeDiscount} off</b> your first booking when you
            reserve through our app.
          </p>
          <Link
            passHref
            href={`/myreservation/getapp?redir=1`}
            className="text-center hover:underline"
          >
            <Image
              src={`${CDN2()}/staticmyapp/qrcodeDownloadAppRedir.png`}
              width={250}
              height={250}
              alt="Download App QR Code"
              className="mx-auto"
              loading="eager"
            />
            <p className="text-lg font-bold">
              Scan the QR code or tap here to download.
            </p>
          </Link>
          <Divider className="-mb-2">or</Divider>
        </div>
      </Modal>
    </div>
  );
}
