import React, { useState, useRef } from 'react';
import { Carousel, Rate } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

function ReviewsCarouselHome({ deviceType, reviews }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef(null);
  const flattenedReviews = reviews;

  const reviewGroups = [];
  for (let i = 0; i < flattenedReviews.length; i += 5) {
    reviewGroups.push(flattenedReviews.slice(i, i + 5));
  }

  const handleAfterChange = (current) => {
    setActiveIndex(current);
  };

  return (
    <div className="flex flex-col relative">
      <h2 className="text-center text-2xl md:text-4xl mt-8 md:mt-4 mb-6 font-bold">
        Our customers love our service
      </h2>
      <p className="text-center text-xl md:text-2xl mb-6">
        See what they're saying about their experiences with us
      </p>
      <div className="pb-4 md:pb-8 flex flex-col">
        {deviceType === 'desktop' ? (
          <div className="relative">
            <LeftOutlined
              className="text-2xl absolute top-1/2 left-0 z-10 text-[#ad5e15]  py-2 px-16 rounded-full transform -translate-y-1/2"
              onClick={() => carouselRef.current?.prev()}
            />

            <Carousel
              effect="scrollx"
              ref={carouselRef}
              infinite={true}
              slidesToShow={1}
              slidesToScroll={1}
              className="overflow-visible"
              speed={500}
              arrows={false}
              dots={false}
              afterChange={handleAfterChange}
            >
              {reviewGroups.map((group, groupIndex) => (
                <div
                  key={groupIndex}
                  className="flex flex-row justify-around md:gap-x-4"
                >
                  {group.map((review, index) => (
                    <div
                      className="w-72 min-h-[20rem] border border-primary rounded-md p-4 space-y-4 flex flex-col justify-between ite"
                      key={index}
                    >
                      <Rate value={5} className="text-base mx-auto" />
                      <p className="text-base italic">"{review.comment}"</p>
                      <p
                        className="font-bold text-sm uppercase
                      "
                      >
                        {review.name},{' '}
                        <span className="font-normal italic">
                          {dayjs(review.date).format('MM/DD/YYYY')}
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </Carousel>
            <RightOutlined
              className="text-2xl absolute top-1/2 right-0 z-10 text-[#ad5e15] py-2 px-16 rounded-full transform -translate-y-1/2"
              onClick={() => carouselRef.current?.next()}
            />
          </div>
        ) : (
          <Carousel
            effect="scrollx"
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            className="overflow-visible pb-4 px-6 md:px-28"
            speed={500}
            arrows
            prevArrow={<LeftOutlined />}
            nextArrow={<RightOutlined />}
            afterChange={handleAfterChange}
          >
            {flattenedReviews.map((review, index) => (
              <div
                className="min-h-[14rem] border border-primary rounded-md p-4 space-y-4 flex flex-col"
                key={index}
              >
                <Rate value={5} className="text-base mx-auto" />
                <p className="text-base h-1/2">{review.comment}</p>
                <p className="font-bold text-sm uppercase">
                  {review.name},{' '}
                  <span className="font-normal">
                    {dayjs(review.date).format('MM/DD/YYYY')}
                  </span>
                </p>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
}

export default ReviewsCarouselHome;
